<template>
  <section class="white" style="height: 100%">
    <Dialog v-if="action != 'form'" :showDialog="true" :maxWidth="600">
      <template slot="content">
        <div class="ma-3">
          <component :is="actions[action]" @change-action="action = $event" />
        </div>
      </template>
    </Dialog>
    <component v-else :is="actions[action]" @change-action="action = $event" />
  </section>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
  name: "Avaliacao",
  components: {
    Dialog,
  },
  data() {
    return {
      actions: {
        loading: () =>
          import(
            "@/modules/avd/avaliacoes/components/avaliacaoComponents/Loading.vue"
          ),
        invalid: () =>
          import(
            "@/modules/avd/avaliacoes/components/avaliacaoComponents/Invalid.vue"
          ),
        evaluation: () =>
          import(
            "@/modules/avd/avaliacoes/components/avaliacaoComponents/Evaluation.vue"
          ),
        form: () =>
          import(
            "@/modules/avd/avaliacoes/components/avaliacaoComponents/Form.vue"
          ),
        finishEvaluation: () =>
          import(
            "@/modules/avd/avaliacoes/components/avaliacaoComponents/EvaluationFinish.vue"
          ),
      },

      /**
       * @types loading / invalid / evaluation / form
       */
      action: "loading",
    };
  },
  mounted() {
    const data = {
      formUuid: this.$route.params.uuid_form,
      collaboratorUuid: this.$route.params.uuid_collaborator,
    };

    this.$store
      .dispatch("avdFormulariosColaboradores/findFormOfCollaboratorByUuid", data)
      .then((response) => {
        switch (response.status) {
          case "A":
            this.action = "evaluation";
            break;
          case "EP":
            this.action = "form";
            break;
          default:
            this.action = "invalid";
            break;
        }
      })
      .catch(() => {
        this.action = "invalid";
      });
  },
  computed: {},
};
</script>
