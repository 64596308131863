<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card :elevation="elevation" :color="color" class="pa-2">
      <slot name="content"></slot>
      <v-card-actions v-if="$slots.actions">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericDialog",
  props: {
    showDialog: {
      require: true,
      default: false,
    },
    maxWidth: {
      require: false,
      default: 290,
    },
    elevation: {
      require: false,
      default: 0,
    },
    color: {
      require: false,
      default: "white",
    },
  },
};
</script>
